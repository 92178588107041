.accountsPage-content {
  margin-left: -16px;
  display: flex;
  height: 100%;

  .page {
    padding-top: 0; }

  .page-top > .search:first-child {
    margin-right: 0; } }


.accounts-tabs {
  margin-top: -16px; }

.customer-page-blind-table {
  .table-row {
    height: 50px;
    .table-cell-content {
      line-height: 18px; } } }

[dir = 'rtl'] {
  .accountsPage-content {
    margin-left: unset;
    .layout {
      margin-right: -16px; }
    .accounts-page-customers-table {
      .table-cell {
        &:nth-child(4) {
          .definition {
            direction: rtl !important;
            justify-content: flex-start; } } } }

    .accounts-page-installers-table {
      .table-cell {
        &:nth-child(6) {
          .definition {
            direction: rtl !important;
            justify-content: flex-start; } } } } }

  .customer-page-blind-table {
    .table-cell {
      &:first-child {
        .table-cell-content {
          padding-right: 32px;
          padding-left: 0;
          .definition {
            direction: rtl !important;
            justify-content: flex-start;
            .definition-detail {
              margin-right: 0;
              margin-left: auto; } } } } } } }

