
.communication {
  border: 2px solid currentColor;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 16px;
  border-radius: 2px;
  font-size: var(--font-size-small);
  text-align: center;
  font-weight: bold;
  color: var(--text-heading2-subtitle);

  &:not(:first-child) {
    margin-left: 8px; } }

.communication--online {
  color: var(--color-success); }

.communication--connected {
  color: var(--color-info); }

.communication--offline {
  color: var(--color-error); }

.communication--none {
  color: var(--icon-disabled); }

[dir = 'rtl'] {
  .communication {
    &:not(:first-child) {
      margin-left: unset;
      margin-right: 8px; } } }
