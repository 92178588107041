

.permissions {
  .form-row {
    height: 48px;
    padding: 0 8px; }

  .form-row-content {
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0; }

  .form-row-label {
    margin-right: 16px; }

  .form-row-label + .form-row-content:last-child {
    width: 400px; } }

.permissions-sublist {
  margin-left: 20px; }

.card-content > .permissions-group {
  &:not(:first-child) {
    margin-top: 36px; } }

.permissions-group {
  position: relative;
  transition: background-color  var(--time); }

.permissions-group--label {
  text-transform: uppercase;
  font-weight: 500;
  color: var(--text-heading2-subtitle);
  padding-left: 20px;
  cursor: pointer; }

.permissions-group--hasAllowed {
  .permissions-group--label {
    font-weight: bold;
    color: var(--text-heading1); } }

.permissions-tooltip {
  display: inline-block;
  padding-left: 8px;
  cursor: help; }

.permissions-group-icon {
  position: absolute;
  left: 8px;
  top: 50%;
  color: var(--text-heading1);
  transform: translateY(-50%) rotate(-90deg); }

.permissions-group-icon--active {
  transform: translateY(-50%); }

[dir = 'rtl'] {
  .permissions {
    .card-header {
      padding: 24px 32px; }

    .form-row-content {
      .toggle {
        margin-right: 0; } }

    .permissions-tooltip {
      padding-right: 8px;
      padding-left: 0; } }

  .permissions-group {
    .permissions-group--label {
      padding-left: unset;
      padding-right: 20px; } }

  .permissions-group-icon {
    right: 8px;
    left: unset;
    transform: translateY(-50%) rotate(90deg); }

  .permissions-group-icon--active {
    transform: translateY(-50%) rotate(0deg); }

  .permissions-sublist {
    margin-left: unset;
    margin-right: 20px;
    .form-row-label {
      margin-left: 16px;
      margin-right: unset; } } }


