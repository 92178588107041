.featureNotSupported {
  display: inline-block;
  line-height: 22px;
  height: 100%;
  vertical-align: middle;
  color: var(--text-heading1);

  .empty {
    display: inline; }

  .ava {
    display: inline-block;
    flex-shrink: 0;
    line-height: 22px;
    height: 22px;
    margin-right: 4px; } }

.dropDown-content {
  .featureNotSupported + .featureNotSupported {
    margin-top: 8px; } }
