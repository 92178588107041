

.severity-row {
  display: flex;

  .severity:not(:first-child) {
    margin-left: 8px; } }

.severity--info {
  .severity-icon, .severity-icon-wrapper {
    opacity: var(--opacity-text-secondary); } }

.severity--danger {
  .severity-icon, .severity-icon-wrapper {
    color: var(--color-error); } }

.severity--warning {
  .severity-icon, .severity-icon-wrapper {
    color: var(--color-warning); } }

.severity-icon {
  width: 18px;
  vertical-align: -3px;
  margin-right: 4px; }

