[dir = 'rtl'] {
  .page-content {
    padding-left: unset;
    padding-right: 16px;
    .layout {
      margin-left: 0; }
    .table {
      margin-right: unset;
      margin-left: 16px;
      .table-cell:first-child {
        .table-cell-content {
          &:first-child {
            padding-left: 6px;
            padding-right: 12px; } } } }
    .table-selection {
      .check {
        padding-left: unset;
        padding-right: 12px; } } }

  .dropDown-icon {
    margin-left: unset;
    margin-right: 6px; }

  .blindModal-card-tableWrapper {
    .table-header-cell {
      &:first-child {
        padding-right: 32px; } }

    .navBlind-close {
      left: 7px;
      right: unset; } }

  .blindModal-content, .blindModalWithPagination, .doubleBlindModal-item, .doubleBlindModal {
    right: unset;
    left: 0;
    transform: translate3d(0, 0, 0); }

  .blindModal-card-tableWrapper-title-count {
    margin-right: 7px; }

  .interactiveUserBlind-info-item {
    .interactiveUserBlind-info-icon {
      margin-right: unset;
      margin-left: 6px; } }

  .doubleBlindModal-item {
    animation: doubleBlindModal-item-rtl .25s;
    &:nth-child(3) {
      .card-header--blindModal {
        direction: ltr;
        padding-left: 32px; } } }

  .blindModal-card-tableWrapper {
    .table-cell {
      &:last-child {
        .table-cell-content {
          padding-right: unset;
          padding-left: 32px;
          .btn-content {
            direction: revert; } } } } }

  .btn-icon--offset {
    margin-right: unset;
    margin-left: 8px; }

  .card-header {
    padding-right: 16px;
    padding-left: unset; }

  .card-header-close {
    margin-left: 8px; }

  .radiobox-label {
    margin-left: 0;
    margin-right: 8px; }

  .card-close, .input-icon {
    right: unset;
    left: 4px; }

  .form-content {
    .col {
      &:not(:last-child) {
        margin-right: unset;
        margin-left: 16px; } } }

  .toggle {
    margin-left: unset;
    margin-right: 25px; }

  .checkbox-label {
    margin-left: unset;
    margin-right: 8px; }

  .card-actions {
    text-align: left; }

  .bar {
    .title {
      &:not(:first-child) {
        margin-left: unset;
        margin-right: 16px; }
      .definition-title {
        margin-left: auto; }
      .definition-detail {
        margin-left: auto; } }
    .btns {
      &:not(:first-child) {
        margin-left: unset;
        margin-right: 7px;
        .pager-next {
          margin-right: 0; } } }
    .btn {
      &:not(:first-child) {
        margin-left: unset !important;
        margin-right: 7px; }
      &:after {
        left: unset;
        right: 0; } }

    .search-bar {
      &:not(:first-child) {
        margin-left: unset;
        margin-right: 7px; } }

    .search-box {
      padding-left: 24px;
      padding-right: 42px;
      .search-box-icon {
        left: unset;
        right: 0; }
      .search-autoComplete-clear {
        right: unset;
        left: 0; } } }

  .btn--dropDown {
    margin-left: unset !important;
    margin-right: 7px; }

  .menu {
    text-align: right;
    .menu-item {
      .toggle {
        margin-right: 0; }
      svg {
        margin-right: unset;
        margin-left: 16px; } } }

  .tags {
    .tag--icon {
      .tag-icon {
        margin-right: 4px;
        margin-left: -2px; } } }


  .disclaimer {
    svg {
      margin-right: unset;
      margin-left: 12px; } }

  .definition {
    direction: initial !important;
    display: flex;
    justify-content: flex-end; }

  .tooltip-container--left {
    justify-content: flex-end; }

  .table-selection-bar {
    padding-right: 12px;
    padding-left: unset; }

  .tab-service-icon {
    margin-left: unset;
    margin-right: 4px; }

  .calendar-item--interactive-dropDown-container {
    .slideDown-container {
      .definition--multiLine {
        direction: unset !important; } } }

  .progress--inline {
    margin-left: 8px;
    margin-right: unset; }

  .partition + .partition {
    border-left: none;
    border-right: 1px solid var(--line-general); }

  canvas {
    direction: ltr; }

  .video-drop-down-button-dropDown-container {
    right: unset!important;
    left: 12px; } }

