

.app {
  height: 100%;
  display: flex; }

.app-aside {
  position: relative;
  flex-shrink: 0;
  width: 100px;
  height: 100%;
  display: flex;
  flex-flow: column;
  z-index: 6; }

.app-aside-content {
  flex-grow: 1;
  overflow: visible;
  height: 100%;
  position: fixed;
  width: 100px; }

.app-aside-logo {
  margin: 20px 16px 32px;
  flex-shrink: 0;
  cursor: pointer;
  max-width: 160px;
  .logo--custom {
    width: 100%;
    image {
      width: 100%; } }

  .logo {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: .9; } }

.app-aide-logo-container {
  display: flex;
  .burger-icon {
    cursor: pointer;
    margin: 36px 0 0 16px; } }

.app-aside-title {
  font-size: var(--font-size-small);
  color: var(--text-heading2-subtitle);
  text-transform: uppercase;
  font-weight: 500;
  margin: 12px 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.app-aside-title-icon {
  max-width: 18px;
  height: 18px;
  cursor: pointer; }

.main-content {
  width: calc(100% - 100px);
  height: 100%;

  &.sidebar-pinned {
    .layout.page {
      width: calc(100% - 402px); } } }

.page {
  width: initial;
  padding-top: 84px; }

.about {
  width: 350px !important;

  .card-header {
    background: var(--jci-color-blue) linear-gradient(45deg, #2A2A9D, var(--jci-color-blue));
    color: white;
    text-transform: none;
    padding: 24px 32px;
    border-radius: 2px 2px 0 0;
    justify-content: center;
    display: flex;

    .logo--custom {
      margin-left: 0;
      padding: 0;
      svg {
        width: 100%; } } }

  .card-close {
    color: white; }

  .card-content {
    margin-top: 36px;
    padding-left: 48px;
    padding-bottom: 24px; } }

.card-about-license {
  padding-bottom: 0;

  .block:first-child {
    margin-top: 0; }

  .block + .block {
    margin-top: 40px; }

  .table {
    margin-left: -32px;
    margin-right: -32px; }

  .table-cell:first-child .table-cell-content {
    padding-left: 32px; } }


.card-about-servernotes {
  padding: 0 32px;
  height: 600px;

  .block:first-child {
    margin-top: 0;
    .about-info:first-child {
      margin-top: 0; } }

  .servernotes {
    white-space: pre-wrap;
    margin-top: 48px; } }


.about-info {
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  white-space: nowrap;

  &.timezone {
    white-space: break-spaces; }

  .hint {
    font-size: 11px; } }

.editSettingsCard {
  .tab--active {
    &::after {
      bottom: 0; } } }

[dir="rtl"] {
  .toggle-box {
    margin: 1px 0 0 3px; }

  .checkbox-label {
    margin-left: unset;
    margin-right: 8px; } }
