.emptyPageCard {
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-heading2-subtitle);
    padding: 24px;

    >svg {
      color: var(--icon-primary);
      width: 60px;
      height: 60px; } }

  .error {
    height: initial;
    min-height: initial; }

  .error-title {
    margin-bottom: 0;
    text-transform: uppercase; } }
