.adaptiveTable {
  padding: 16px;
  background-color: var(--background-success);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 16px; }

.adaptiveTable-item {
  padding-top: 16px;
  border-bottom: 1px solid var(--line-general);

  &:last-child {
    border-bottom: none; } }

.adaptiveTable-item--wide {
  grid-column-start: 1;
  grid-column-end: 3; }
