.sidebar-pinned {
  > .main-sidebar {
    box-shadow: none; } }

.main-sidebar {
  position: fixed;
  right: 0;
  height: calc(100% - 68px);
  bottom: 0;
  width: 402px;
  background-color: var(--card-primary);
  z-index: 4;
  box-shadow: var(--box-shadow-sidebar);
  padding-left: 16px;

  .tabs {
    width: 153px;
    box-shadow: none;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 402px;
      height: 1px;
      background-color: var(--line-general);
      left: -16px; } }

  .pin-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 26px;
    cursor: pointer;
    color: var(--icon-primary); }

  .tabs-layout {
    height: 100%;

    .tab {
      font-size: 14px; }

    .processes {
      height: 100%;
      margin-top: -16px;

      .processes-content {
        height: calc(100% - 130px);
        overflow-y: auto;
        margin-top: 24px;

        .table {
          .table-content {
            box-shadow: none;
            padding-bottom: 16px; }
          .table-row {
            border-bottom: none;
            &:last-child {
              border: none!important; }
            .table-cell {
              width: 100%;
              .table-cell-content {
                padding: 0; } } }
          .table-title {
            font-size: 18px;
            background: none;
            text-align: left;
            text-transform: capitalize;
            position: initial;
            padding: 0 8px;
            margin-bottom: 10px; } } }

      .processes-action {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-content: center;
        width: 100%;
        justify-content: flex-end;
        padding: 16px;
        gap: 7px;
        border-top: 1px solid var(--line-general);
        background-color: var(--card-primary);

        .processes-clear-all {
          background-color: var(--button-light); } }

      .process {
        padding: 12px 0 24px 39px;
        margin-right: 12px;
        border-radius: 2px;
        margin-bottom: 4px;
        &:hover {
          background-color: var(--background-success);
          cursor: pointer;
          .process-untrack {
            display: initial; }
          .process-time {
            display: none; } }

        .process-dropdown {
          position: absolute;
          left: 15px;
          top: 50%;
          transform: rotate(270deg); }

        &--active {
          background-color: var(--line-general);

          .process-dropdown {
            transform: rotate(90deg); } }

        .process-counters {
          padding-left: 39px;
          gap: 3px; }

        .process-title {
          .hint {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            color: var(--text-heading2-subtitle);

            .process-time {
              opacity: 1; }

            .hint-panel {
              text-transform: uppercase; } } }

        .process-untrack {
          position: absolute;
          top: 24px;
          right: 27px;
          display: none;
          color: var(--text-heading1);

          &:hover > svg {
            transition: 200ms;
            opacity: 1; } } } } } }

[dir="rtl"] {
  .main-sidebar {
    direction: rtl;
    right: unset;
    left: 0;
    padding-left: unset;
    padding-right: 16px;

    .tabs::after {
      left: unset;
      right: -16px; }

    .pin-btn {
      right: unset;
      left: 26px; }

    .tabs-layout {
      .processes {
        .processes-content {
          .table {
            .table-title {
              text-align: right; } } }

        .process {
          padding: 12px 39px 24px 0;
          margin-right: unset;
          margin-left: 12px;

          &.process--active {
            .process-dropdown {
              transform: rotate(270deg); } }

          .process-title {
            padding-right: unset;
            padding-left: 20px; }

          .process-untrack {
            right: unset;
            left: 27px; }

          .process-dropdown {
            left: unset;
            right: 15px;
            transform: rotate(90deg); }

          .process-counters {
            padding-left: 12px;
            padding-right: 39px; } } } } } }
