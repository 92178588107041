.blindModal-card {
  .blindModal-card-content {
    display: flex;
    flex-direction: column;
    padding: 16px 0 0;

    .bar--divider {
      height: 16px;
      margin-bottom: 16px; }

    .blindModal-card-content-search {
      display: flex;
      height: auto;
      padding: 0 32px 16px;

      .search {
        flex-grow: 1;

        .search-bar {
          background-color: var(--button-light); } } } }

  .card-header {
    text-transform: none;

    .definition-detail {
      word-break: break-all; } } }

.blindModal-card-actions-buttons {
  display: flex;
  flex-grow: 1;
  justify-content: space-between; }

.blindModal-card-actions-pager {
  display: flex;
  flex-grow: 1;
  justify-content: right;

  .btn-icon {
    height: 14px; } }
