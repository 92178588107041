.blindModalDefinition {
  margin: 0 32px; }

.blindModalDefinition-item {
  margin-top: 12px;
  display: flex; }


.blindModalDefinition-item-title {
  color: var(--text-heading2-subtitle);
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  align-items: flex-end;
  display: flex;
  width: 143px; }
