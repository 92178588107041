

.panelRemarks {
  .card-header {
    border-bottom: 1px solid var(--line-general); }

  .card-content {
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
    padding-bottom: 0;
    height: calc(100% - 72px);
    padding-right: 0;
    padding-left: 0; }

  .scroll {
    padding-right: 16px;
    padding-left: 24px; } }

.panelInfo-addRemark {
  padding: 24px;
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  z-index: 1;
  transition: box-shadow  var(--time) var(--ease-md);
  border-top: 1px solid var(--line-general);

  .form-field {
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 12px; }

  .input-field {
    max-height: calc(50vh - 100px); } }

.panelInfo-remarks-title {
  text-align: center;
  margin-top: 32px;

  &:first-child {
    margin-top: 12px; } }

[dir = 'rtl'] {
  .panelRemarks {
    .scroll {
      padding-left: 16px;
      padding-right: 24px; }
    .comment-content {
      margin-right: 12px;
      margin-left: unset; }

    .comment-text {
      &:before {
        right: -8px;
        left: auto;
        transform: scaleX(-1); } }

    .comment--mine {
      margin-left: unset;
      margin-right: 64px;
      .comment-text {
        &:before {
          right: auto;
          left: -8px;
          transform: scaleX(-1); } } } } }

