.editSettingsCard {
  .tabs-layout {
    min-height: 591px; } }

.editSettings-layout {
  display: flex;
  flex-direction: column;

  .card-content {
    flex-grow: 1; } }
