.connection-status {
  margin: 0 -32px;

  table {
    width: 100%;
    font-weight: 500;

    thead {
      color: var(--text-heading2-subtitle);
      background-color: var(--background-success);
      font-size: var(--font-size-small);
      text-transform: uppercase;

      th {
        height: 30px!important; } }

    tbody {
      font-size: var(--font-size-primary); }

    tr {
      .connection-block-title {
        width: 76px;
        padding: 8px 9px 8px 0;
        overflow: hidden;
        text-overflow: ellipsis; }
      .connection-block-title-singleline {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }

      td.connection-block-title-cell, th:first-child {
        padding-left: 32px; }

      td:last-child {
        padding-right: 32px; }

      td, th {
        vertical-align: middle;
        text-align: left;
        height: 44px;
        border-bottom: 1px solid var(--line-general);
        font-weight: 400; } } }

  .buttons-container {
    padding-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .dls-connection-buttons {
      height: 28px;
      width: 111px;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .btn-content {
        padding: 0; } } } }

.dls-connection-buttons-drop-down-slide {
  transition: unset;
  .menu-item-icon {
      opacity: 1; }

  .status-cell {
    padding-left: 16px;
    padding-right: 16px; }
  .channel-cell {
    padding-left: 16px;
    padding-right: 16px; }
  .encryption-cell {
    padding-right: 40px; } }

[dir = 'rtl'] {
  .connection-block {
    .buttons-container {
      padding-right: 0;
      padding-left: 32px; }
    .connection-block-title-cell {
      padding-left: 0;
      padding-right: 32px; }
    .connection-block-title {
      text-align: right;
      margin-left: auto;
      margin-right: 0; } }

  .connection-status {
    table {
      tr {
        th:first-child {
          padding-right: 32px;
          text-align: right; } } } } }
