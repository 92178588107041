.locations-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.locations-row {
  width: 50%;
  padding: 0 24px;

  .form-field {
    max-width: 240px; }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--card-primary) inset !important; } }

.form-row--changed {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--table-active) inset !important; } }

.locations-list-wrapper {
  .bottom-list-scrolled {
    &:last-child {
      margin-bottom: 16px; } } }

[dir = 'rtl'] {
  .locations-list-wrapper {
    .bar--children-left {
      margin-left: 0; } } }
