.centralStation-general {
  .scroll {
    padding-right: 16px; }

  .page-card {
    max-width: 1200px;
    margin: 0 auto; } }

.centralStation-communication-pageCard-grid {
  margin-right: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px; }

.centralStation-communication-pager {
  margin-right: 8px; }

.centralStation-communication-search {
  .search-box {
    padding-right: 0;
    min-width: 453px; } }

.central-station-tabs {
  margin-top: -16px; }

[dir = 'rtl'] {
  .centralStation-communication-pager {
    margin-right: unset;
    margin-left: 8px; }

  .centralStation-communication-search {
    margin: 0 44px 0 0 !important;
    .search-box--withIcon {
      padding-right: 45px; } }

  .centralStation-general {
    .card-header {
      .definition {
        display: inline-flex;
        direction: rtl !important; } } }

  .centralStation-communication-search {
    margin: 0 0 0 44px !important; }

  .centralStation-communication {
    margin-right: -16px; } }
