.batchInfo-list-item {
  height: 80px;
  cursor: pointer;
  padding: 10px 12px;
  transition: background-color var(--ease-md)  var(--time);
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: flex-start;
  width: 100%;
  row-gap: 7px;
  border: 1px solid var(--line-general);
  background-color: var(--background-menu-dropdown);
  box-shadow: var(--box-shadow-dropdown);
  border-radius: 2px;
  position: relative;

  &:hover {
    background-color: var(--background-app-bar);
    box-shadow: var(--box-shadow-app-asside);
    transition: 200ms;

    &:has(.batchInfo-list-item-stopProcess) {
      .batchInfo-list-item-details-duration {
        display: none; } }

    .btn.batchInfo-list-item-stopProcess {
      display: initial;
      box-shadow: none!important; } } }

.batchInfo-list-item-details {
  display: flex;
  width: 100%; }

@mixin overflowTooltipStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.batchInfo-list-item-details-panel {
  width: 35%;
  font-weight: 500;
  @include overflowTooltipStyle; }

.batchInfo-list-item-details-duration {
  width: 65%;
  text-align: right;
  color: var(--text-heading2-subtitle);
  font-size: 12px;
  @include overflowTooltipStyle; }

.batchInfo-list-item-status {
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 12px;

  .definition {
    font-weight: 400;
    font-size: 12px;

    &.running {
      color: var(--color-info);

      + .progress .progress-bar {
        background-color: var(--color-info); } } }

  .progress {
    width: 100%;
    height: 4px;
    border-radius: 4px;

    &.batch-runner--failed {
      .progress-bar {
        background-color: var(--color-error); } }

    .progress-value {
      display: none; } } }

.btn.batchInfo-list-item-stopProcess {
  width: 20px;
  height: 20px;
  display: none;
  position: absolute;
  right: 14px;
  top: 30px;
  background: none;
  border: none;
  line-height: 14px;

  &:active {
    background: none!important;
    box-shadow: none!important;
    color: initial; }

  .btn-content {
    line-height: 14px; }

  .btn-icon {
    height: 14px;
    width: 14px;
    color: var(--text-heading2-subtitle);

    &:hover {
      transition: 200ms;
      color: var(--text-heading1); } } }

[dir = 'rtl'] {
  .definition + .runners-table-process-cell {
    direction: ltr !important; }

  .batches-table-status-cell {
    position: relative;
    padding-right: 24px;
    svg {
      position: absolute;
      right: 0; } } }

[dir="rtl"] {
  .batchInfo-list-item-status {
    align-items: flex-start;
    .definition {
      direction: rtl !important; } }

  .definition-icon {
    margin-right: unset;
    margin-left: 8px; }

  .batchInfo-list-item-details-duration {
    text-align: left; }

  .btn.batchInfo-list-item-stopProcess {
    right: unset;
    left: 14px; } }

