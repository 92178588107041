

.worries {
  .worry {
    margin-right: 8px; } }

.worry {
  display: inline-block;
  color: var(--text-heading2-subtitle); }

.worry-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle; }

.worry--alarm {
  color: var(--color-error); }

.worry--alert {
  color: var(--color-warning); }

.worry--suspended {
  position: relative;
  opacity: .3;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 120%;
    height: 2px;
    left: 55%;
    top: 55%; }

  &:before {
    background-color: var(--card-primary);
    transform: translate(-50%, -50%) rotate(45deg) translateY(-1px);
    z-index: 2; }

  &:after {
    background-color: currentColor;
    transform: translate(-50%, -50%) rotate(45deg) translateY(1px);
    z-index: 3; } }


.worryDefinition {
  min-width: 250px;
  padding-left: 28px;
  position: relative;

  .worry {
    position: absolute;
    left: 0;
    top: .65em;
    transform: translateY(-50%); }

  &:not(:first-child) {
    margin-top: 16px; } }

.worryDefinition-name {
  font-weight: 500; }

.worryDefinition-description {
  margin-top: 4px;
  font-size: var(--font-size-small);
  opacity: var(--opacity-text-secondary);
  max-width: 600px;
  word-wrap: break-word; }
