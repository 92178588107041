.devices {
  margin-right: 16px;
  > .bar {
    margin: 0 24px 12px 12px;
    flex-wrap: wrap;
    height: auto; }

  .definition-title {
    display: block;
    font-size: var(--font-size-medium);
    margin-bottom: 4px; }

  .definition-detail {
    margin-left: 0; } }

.devices-sidebar-button {
  margin-right: 8px; }

.devices-content {
  overflow-y: scroll; }

.rssi .title .definition-detail {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-heading2-subtitle);
  text-transform: none !important;
  opacity: 1; }

.rssi-noMeasurements {
  text-align: center;
  margin: 24px 42px 64px;
  color: var(--text-heading2-subtitle);

  .title {
    color: var(--text-heading1);
    margin-bottom: 8px; } }

.rssi-noMeasurements-content {
  margin: 12px 8px; }

.configuration-outdateNotice {
  text-align: center;
  margin: 12px 42px 42px;
  color: var(--text-heading2-subtitle);

  .title {
    color: var(--text-heading1);
    margin-bottom: 8px; } }

.configuration-outdateNotice--content {
  margin: 12px 8px; }

.plinkDebug {
  .hint {
    display: block;
    min-width: 100px; }

  > .btn:last-child {
    margin-top: 16px; } }

.blockSubsection + .blockSubsection {
  margin-top: 24px; }

.dls-connection-buttons-drop-down-slide {
  transition: unset;
  .menu-item:hover {
    background-color: var(--button-light); } }

[dir = 'rtl'] {
  .devices {
    margin-right: 0 !important; } }
