.selectPgmType {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--line-general);
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: repeat(auto-fill, 44px);
  grid-gap: 12px; }

.selectPgmType-title {
  grid-column-start: 1;
  grid-column-end: -1;
  color: var(--text-heading2-subtitle);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px; }

.selectPgmType-item {
  cursor: pointer;
  color: var(--text-heading2-subtitle);
  font-size: 10px;
  line-height: 1.4; }

.selectPgmType-item {
  &:active, &:focus {
    .selectPgmType-item-iconContainer {
      border-color: var(--color-primary);
      box-shadow: var(--box-shadow-elevation-2); } } }

.selectPgmType-item--active {
  .selectPgmType-item-iconContainer {
    background-color: var(--message-primary);
    border: 0.5px solid var(--color-primary);
    color: var(--color-primary);
    border-color: var(--color-primary); } }

.selectPgmType-item-iconContainer {
  padding: 7px;
  height: 44px;
  width: 44px;
  background-color: var(--button-light);
  margin-bottom: 7px;
  border: 0.5px solid transparent;
  border-radius: 2px;
  transition: border-color var(--ease-md)  var(--time), box-shadow var(--ease-shadow)  var(--time);

  >svg {
    max-width: 100%;
    max-height: 100%;

    * {
      fill: currentColor; } } }
