

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-flow: column;
  padding: 32px;

  .card-actions {
    padding-left: 32px;
    align-items: center; }

  .link {
    display: block;
    margin: 32px 0 40px 32px;
    font-size: var(--font-size-primary);
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    line-height: 17px; } }

[dir = 'rtl'] {
  .login {
    .link {
      margin: 32px 32px 40px 0; } } }

.login-logo {
  color: white;
  margin-bottom: 16px;
  font-size: 11px;
  width: 300px;

  .logo {
    display: flex;
    font-size: 25px;
    letter-spacing: 0.5px;
    align-items: center;
    justify-content: center; }

  .logo-icon {
    width: 150px;
    height: 84px; }

  .logo--custom,
  .logo--custom > svg {
    width: 100%; } }

.login-form {
  width: 504px;
  margin: 24px;
  .form-field {
    margin: 35px 0; }
  .card-content {
    max-height: 255px;
    padding: 0 32px; }
  .card-actions {
    padding: 0 32px 16px 32px;
    display: block; } }

.login-form-header {
  text-transform: none;
  font-size: var(--font-size-super-big);
  font-weight: 500;
  margin-bottom: 15px;
  padding-bottom: 0; }

.login-form-button {
  width: 100%;
  height: 50px;
  margin-bottom: 24px;
  font-size: 14px; }

.login-form-content-label {
  font-size: 16px; }

.login-form-back {
  svg {
    height: 9px;
    margin: 0 5px 2px 0; } }

.login-form {
  .form-field-label {
    font-size: 14px; } }

.login-form-version {
  display: block;
  width: 100%;
  color: var(--color-gray-600);
  text-align: center;
  font-family: Mulish, sans-serif;
  font-weight: normal;
  font-size: var(--font-size-primary);
  line-height: 15px;
  margin-bottom: 24px; }

.login-form-forgot-form {
  height: 392px;
  .form-content {
    height: 392px; }
  .card-content {
    padding: 0 30px 30px; } }

.login-form-back {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
  color: var(--text-heading1);
  text-align: center;
  font-size: var(--font-size-medium);
  line-height: 17px;
  text-transform: none; }

.login-form-warning-area {
  border: var(--color-primary) solid 1px;
  padding: 15px 10px;
  margin: 20px 0;
  display: flex; }

.login-form-warning-text {
  color: var(--color-primary);
  text-align: left;
  font-size: var(--font-size-medium);
  line-height: 20px; }

.login-form-icon-info {
  color: var(--color-primary); }

.login-form-icon-wrap {
  display: inline-block;
  width: 40px;
  margin: 0 5px; }

.login-form-phone {
  font-size: var(--font-size-medium);
  color: var(--color-primary);
  margin: 10px 0; }

.login-form-limit-message {
  display: block;
  margin: -30px 0 30px 30px; }

[dir = 'rtl'] {
  .login-form-back {
    svg {
      margin: 0 0 2px 5px;
      transform: scaleX(-1); } }

  .login-form-phone {
    direction: ltr;
    display: flex;
    justify-content: flex-end; }

  .login-form-limit-message {
    margin-right: 30px; }

  .login-form-warning-text {
    text-align: right; } }
