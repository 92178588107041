.sidebar-pinned {
  .partitionDevices-sidebar {
    margin-right: 16px; } }
.panelState {
  padding-bottom: 0!important;

  .table-row--active {
    position: relative;
    background-color: var(--table-active);
    color: var(--text-heading1);

    &:before {
      content: '';
      width: 7px;
      height: 100%;
      position: absolute;
      background-color: var(--table-active);
      mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9JzEwMCcgaGVpZ2h0PScxMDAnPjxwYXRoIGQ9Ik0gMTAwLDUwIDAsMTAwIDAsMCB6IiBmaWxsPSJjdXJyZW50Q29sb3IiLz48L3N2Zz4=);
      mask-size: 100% 100%;
      top: 0;
      left: 100%; } }

  .definition-detail {
    font-size: var(--font-size-small);
    font-weight: 500;
    text-transform: uppercase; } }

[dir="rtl"] .panelState {
  .table-row--active:before {
    transform: rotate(180deg); } }

.panelState-partition {
  padding: 8px; }

.panelState-readyState {
  background-color: rgba(0, 0, 0, .07);
  color: var(--text-heading2-subtitle);

  .circle-icon {
    width: 16px;
    height: 16px; } }

.partitionDevices-sidebar {
  flex-shrink: 0;
  width: 600px;
  margin-left: 16px;

  .scroll {
    margin-top: 16px;
    padding-top: 0;
    border-top: 1px solid var(--line-general); }

  .card {
    height: 100%;
    display: flex;
    flex-direction: column; }

  .card-header {
    background-color: var(--background-success);
    padding: 16px 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--background-success);

    .partitionDevices-sidebar-setLabelBtn {
      margin-left: auto; }

    .card-close {
      position: static;
      margin-left: auto;
      margin-right: -9px; }

    .partitionDevices-sidebar-setLabelBtn + .card-close {
      margin-left: 7px; } }


  .card-content {
    padding: 0;
    height: 100%;
    overflow-y: hidden;
    flex-grow: 1;
    display: flex; }

  .tabs {
    padding: 0 32px;
    margin-top: 24px; }

  .tabs-layout {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .error {
      width: 274px; } }

  .tabs-layout-container {
    flex-grow: 1;
    overflow-y: auto; }

  .partitionDevices-sidebar-openedDevices.tab--active {
    color: var(--color-error);
    &::after {
      background-color: var(--color-error); } }

  .device {
    box-shadow: none;

    &:not(:first-child) {
      border-top: 1px solid var(--line-general); } }

  .card-actions {
    background-color: var(--background-success);
    border-top: 1px solid var(--line-general);
    padding: 16px 32px; } }

.partitionDevices-sidebar-title {
  margin: 0 16px; }

.partitionDevices-sidebar-bypassAll-container {
  background-color: var(--background-success);
  display: flex;
  padding: 18px 32px;
  align-items: center;
  justify-content: space-between;

  .form-field {
    margin-top: 0;
    flex-shrink: 0; } }

.partitionDevices-sidebar-bypassAll-title {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: var(--text-heading2-subtitle); }

.partitionDevices-sidebar {
  .bottom-list-scrolled {
    &:last-child {
     margin-bottom: 0; } } }

.layout:has(.page-interactiveBar--active) .panelPage-tabs {
  margin-bottom: 0; }

.layout:has(.panelPage-tabs) .page-interactiveBar--active {
  margin-bottom: 16px; }

.layout {
  .page-interactiveBar-items:has(.page-interactiveBar--active) {
    height: 56px; } }

[dir = 'rtl'] {
  .partitionDevices-sidebar {
    margin-left: 0;
    .card-header {
      display: flex;
      justify-content: space-between;
      .partitionDevices-sidebar-title {
        margin-left: auto; }
      .card-close {
        margin-left: 9px;
        margin-right: 7px; } }
    .device-openMark {
      left: unset;
      right: 12px; }
    .btns {
      svg {
        transform: rotate(180deg); } } }

  .panelState {
    .table {
      margin-right: unset;
      margin-left: 18px; }

    .table-row--active:before {
      left: unset;
      right: 100%; } }

  .panelState-partition {
    .definition {
      display: contents; } }

  .partitionDevices-sidebar-setLabelBtn {
    margin-left: 0 !important;
    margin-right: auto; } }
