.devices {
    .devices-bar {
        > .form-field--checkbox {
            margin-top: 0;
            margin-right: 16px; } } }

[dir = 'rtl'] {
  .panels-page-panels-table {
    .table-content {
      .table-cell {
        &:last-child {
          div {
            padding-right: 4px;
            padding-left: 12px; } } } } } }
