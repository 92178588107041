.c-notifications-box {
  position: fixed;
  right: 10px;
  top: 78px;
  z-index: 100; }

.c-notification {
  width: 400px;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid var(--line-general);
  background: var(--background-menu-dropdown);
  box-shadow: var(--box-shadow-account);
  position: relative;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px; }
  .process-counters {
    gap: 3px; }
  .process-counter {
    border-radius: 2px;
    height: 4px;
    margin-top: 4px; } }

.c-notification__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: var(--text-heading1);
  margin-bottom: 4px; }

.c-notification__description-wrap {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-heading2-subtitle); }

.c-notification__close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  &:hover .btn-icon {
    color: var(--text-heading1); }
  .btn-content {
    padding: 0;
    line-height: 1; }
  .btn-icon {
    vertical-align: 0;
    width: 10px;
    height: 10px;
    color: var(--icon-primary); } }

[dir="rtl"] {
  .c-notifications-box {
    left: 10px;
    right: auto; }
  .c-notification__close {
    left: 10px;
    right: auto;
    transform: translate(50%, -50%); } }
