.user-status-table {
  .table-content {
    background-color: var(--table-primary); }
  .table-cell-content {
    padding: 0 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px; }
  .table-row {
    min-height: 42px; }
  .table-header {
    .table-header-cell {
      line-height: 14.06px;
      &:nth-child(2) {
        width: 44px !important;
        padding: 0 0 8px 0;
        margin-left: -3px; }
      &:nth-child(3) {
        padding-left: 23px; } } } }

[dir = 'rtl'] {
  .user-status-table {
    .btn-content {
      svg {
        margin-right: 8px; } } } }

.user-status-icon {
  border-radius: 50%; }


.user-status-button-enable, .user-status-button-unblock {
  svg {
    color: var(--color-success); } }

.user-status-button-enable, .user-status-button-unblock, .user-status-button-suspend, .user-button-edit {
  font-size: 12px;
  line-height: 14.06px;
  font-weight: 500;
  height: 28px;
  width: 100%;
  padding: 0;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  .btn-content {
    padding: 9px; } }

.user-status-blocked {
  color: var(--color-error); }

.user-status-suspended {
  color: var(--icon-primary); }

.user-status-online {
  color: var(--color-success); }

.user-button-edit {
  width: 32px;
  height: 28px;
  .btn-content {
    width: 32px;
    height: 28px;
    padding: 4px 3px; }
  svg {
    width: 12px;
    height: 12px; } }

[dir = 'rtl'] {
  .user-button-edit {
    svg {
      margin-right: 0 !important; } } }

.user-status-table-row {
  .table-cell {
    &:nth-child(2) {
      height: 42px;
      padding: 11px 6px;
      border-left: 1px solid var(--line-table);
      border-right: 1px solid var(--line-table); }
    &:nth-child(3) {
      padding-left: 16px; } } }

.user-bar-button-enable {
  svg {
    color: var(--color-success); } }
