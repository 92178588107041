.brokers-settings-card {
  background: transparent;
  box-shadow: none;
  .card-content {
    font-style: normal;
    color: var(--text-heading1);
    padding: 16px 0 0 0;
    .table-header {
      background: transparent; } } }

.brokers-button {
  display: block;
  margin: 0 0 0 auto; }

[dir = 'rtl'] {
  .brokers-button {
    margin: 0 auto 0 16px; } }
