.customerRemarks-title {
  text-transform: uppercase;
  margin-bottom: 7px;
  font-size: 12px;
  color: var(--text-heading2-subtitle);
  font-weight: 500; }

@media screen and (min-width: $window-large) {
  .customerRemarks {
    display: flex;
    flex-wrap: wrap;
    column-gap: 7px; }

  .customerRemarks-title {
    width: 100%; } }

@media screen and (min-width: $window-x-large) {
  .customerRemarks {
    display: block; } }

