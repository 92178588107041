.eventInitiate-modal {
  .disclaimer {
    display: block;
    color: var(--text-heading2-subtitle);
    width: 100%;
    line-height: 22px;
    border: none;
    padding: 0;
    svg {
      display: none; }
    ul {
      padding-left: 20px; } }

  .card-header {
    color: var(--text-heading1);
    padding-top: 40px; }
  .form-field {
    margin: 40px 0; }
  .form-field-label {
    color: var(--text-heading2-subtitle); } }
