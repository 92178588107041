.sidebar--bookmark {
  min-width: 170px;
  width: 20%;
  padding: 0 0 16px 16px;

  > .btn {
    margin-bottom: 8px;
    text-align: left; } }

[dir = 'rtl'] {
  .sidebar--bookmark {
    padding: 0 16px 16px 0;
    > .btn {
      text-align: right; } } }
