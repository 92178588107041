.hadDescription {
  border: 1px solid var(--text-heading1);
  border-radius: 2px;
  padding: 12px 10px;
  display: flex;
  transition: height ease-in-out  var(--time);
  cursor: text;
  height: 44px;

  >svg {
    margin-right: 16px; }

  .hadDescription-description {
    flex-grow: 1; }

  .form-field {
    margin-top: 0;
    flex-grow: 1; }

  .input-field {
    padding-bottom: 0;
    line-height: inherit; } }

.hadConfigurationTabTable {
  margin-right: 0!important; }

.hadConfigurationTabTable {
  .table-row {
    &:not(:last-child) {
      border-bottom: none; } } }

.hadConfigurationTabTable-iconValue {
  display: inline-block; }

.hadConfigurationTabTable-iconValue svg {
  height: 20px;
  width: 20px;
  margin-right: 10px; }

.hadConfigurationTabTable-row-labelCell {
  width: 125px;
  color: var(--text-heading2-subtitle); }

.hadConfigurationTabTable-row-valueCell {
  max-width: 370px;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  align-items: center; }

.hadConfigurationTabTable-row-buttonsCell {
  .btn + .btn {
    margin-left: 10px; } }

.modalFormEditHadIcon {
  width: 332px!important; }

.modalFormEditHadIcon-list {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: repeat(auto-fill, 44px);
  grid-gap: 12px; }

.modalFormEditHadIcon-list-item {
  cursor: pointer;
  color: var(--text-heading2-subtitle);
  font-size: 10px;
  line-height: 1.4; }

.modalFormEditHadIcon-list-item {
  &:active, &:focus {
    .selectPgmType-item-iconContainer {
      border-color: var(--color-primary);
      box-shadow: var(--box-shadow-elevation-2); } } }

.modalFormEditHadIcon-list-item--active {
  .modalFormEditHadIcon-list-item-iconContainer {
    background-color: var(--message-primary);
    border: 0.5px solid var(--color-primary);
    color: var(--color-primary);
    border-color: var(--color-primary); } }

.modalFormEditHadIcon-list-item-iconContainer {
  padding: 7px;
  height: 44px;
  width: 44px;
  background-color: var(--button-light);
  margin-bottom: 7px;
  border: 0.5px solid transparent;
  border-radius: 2px;
  transition: border-color var(--ease-md)  var(--time), box-shadow var(--ease-shadow)  var(--time);

  >svg {
    max-width: 100%;
    max-height: 100%;

    * {
      fill: currentColor; } } }

.modalFormEditHadIcon-list-item-iconTitle {
  text-align: center; }

[dir = 'rtl'] {
  .hadConfigurationTabTable-row-valueCell {
    margin-right: unset;
    margin-left: 10px;
    .hadConfigurationTabTable-iconValue {
      svg {
        margin-right: unset;
        margin-left: 10px; } } } }
