.configurations {
  max-width: 600px;
  height: 100%;
  margin: 0 auto 16px;

  > .bar {
    margin-bottom: 16px; } }

.configurations-icon {
  color: var(--text-heading2-subtitle);
  width: 20px; }

.configurations-icon--active {
  color: var(--color-primary); }

.configuration-code-value-wrapper {
  position: relative;
  .input {
    display: flex; } }

[dir = 'rtl'] {
  .configurations {
    .table {
      margin-left: 0 !important; } }

  .central-stations-table {
    .table-cell {
      &:nth-child(5) {
        .definition {
          direction: rtl !important;
          justify-content: flex-start; } } } } }
