.recentHistoryWidget {
  margin: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px 12px;
  height: 60px !important;
  justify-content: space-between;
  background: var(--background-app-bar);
  border-radius: 2px;
  .iconHistory {
    width: 40px;
    height: 40px;
    margin: 10px 12px 0 12px;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid var(--line-general); }
  svg {
    color: var(--icon-primary);
    margin: 7px; } }

.recentHistoryWidget:hover {
  cursor: pointer;
  background: var(--background-success);
  .iconHistory {
    svg {
      color: var(--text-heading1); } } }

.recentHistoryWidget-active {
  cursor: pointer;
  background: var(--line-general);
  .recentHistoryWidget-serial {
    color: var(--color-primary); }
  .recentHistoryWidget-email {
    color: var(--text-heading1); } }

.recentHistoryWidget-title {
  display: flex; }

.recentHistoryWidget-serial {
  margin: 0 10px 0 50px;
  font-size: var(--font-size-primary);
  line-height: 16px;
  font-weight: 500;
  color: var(--text-heading1); }

.recentHistoryWidget-account {
  color: var(--text-heading2-subtitle);
  margin-left: 10px; }

.recentHistoryWidget-email {
  margin: 0 0 1px 50px;
  font-size: var(--font-size-small);
  line-height: 14px;
  color: var(--text-heading2-subtitle); }

.recentHistoryWidget-separator {
  color: var(--text-heading2-subtitle); }

.recentHistoryWidget-dropDown {
  position: absolute;
  top: 4px;
  right: 12px;
  width: 12px;
  opacity: .8;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  transform: rotate(-90deg);
  color: var(--text-heading1); }

.recentHistoryWidget-lightTitle {
  font-size: 12px;
  color: var(--text-heading2-subtitle);
  padding-left: 36px;
  margin-top: 4px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 500; }

.recentHistoryWidget-item {
  display: block;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  position: relative; }

.recentHistoryWidget-item + .recentHistoryWidget-item {
  margin-top: 12px; }

.recentHistoryWidget-item--active {
  color: var(--color-primary); }

[dir="rtl"] {
  .recentHistoryWidget-serial {
    margin: 0 50px 0 10px; }

  .recentHistoryWidget-account {
    margin-right: 10px; }

  .recentHistoryWidget {
    .iconHistory {
      left: unset;
      right: 0; } }

  .recentHistoryWidget-email {
    margin: 0 50px 1px 0; } }
