.logVar {
  display: inline-block;
  padding: 0 4px;
  border-radius: 2px;
  background: var(--button-light); }

.logVar--trigger {
  cursor: pointer; }

.logVar--empty {
  color: var(--icon-disabled);
  font-weight: normal; }

.logVar-more {
  margin-left: .5em;
  opacity: var(--opacity-text-secondary);
  font-size: var(--font-size-small); }

.logVar-more-icon {
  width: 8px; }

.logVar-item {
  font-weight: 500;
  white-space: nowrap;

  &:not(:first-child) {
    margin-top: 12px; } }

.actionLog-page-table {
  .table-content {
    &:last-child {
      padding-bottom: 0 !important; } } }

[dir = 'rtl'] {
  .actionLog-page-table {
    .table-row {
      .table-cell {
        &:first-child {
          .table-cell-content {
            .definition {
              direction: rtl !important;
              justify-content: flex-start;
              margin-right: 5px; } } } } }

    .logVar-more {
      display: inline-block; }
    .logVar {
      direction: ltr; } }

  .action-log-table-cell-definition {
    position: relative;
    padding-right: 24px;
    svg {
      position: absolute;
      right: 0; } } }
