.systemSettings {
  height: 100%;

  .scroll {
    padding-right: 16px; }

  .card {
    display: flex;
    flex-flow: column;

    .settings-card {
      margin-bottom: 0; } }

  .table:last-child {
    padding-bottom: 0; } }

.settings-page-tabs {
  margin-top: -16px; }

.mfaGeneral-form, .pgmActivationTimeSlot-form {
  .form-field--togglebox {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 28px;
    border-bottom: 1px solid var(--line-general);

    .checkbox-label {
      margin-left: 0;
      margin-right: auto; } } }

[dir = 'rtl'] {
  .mfaGeneral-form, .pgmActivationTimeSlot-form {
    .checkbox-label {
      margin-left: auto;
      margin-right: 0; } } }

.mfaGeneral-form-enable-toggle, .pgmActivationTimeSlot-form-enable-toggle {
  em {
    font-style: normal;
    color: var(--text-heading2-subtitle);
    &:before {
      content: '\00a0'; } } }

.notification--enabled {
  color: var(--color-success); }

.notification--disabled {
  color: var(--color-error); }

.settings-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  grid-auto-rows: minmax(min-content, max-content);
  .settings-card {
    width: 100%;
    margin: 0; } }

.settings-grid-general {
  .settings-card {
    &:first-child {
      grid-row-start: 1;
      grid-row-end: 3; } } }

.settings-grid-resolve {
  .settings-card {
    &:last-child {
      grid-row-start: 1;
      grid-row-end: 3; } } }

.settings-grid-interactive {
  .settings-card {
    &:first-child {
      grid-row-start: 1;
      grid-row-end: 3; } } }

.settings-grid-mfa {
  .settings-card {
    &:first-child {
      grid-row-start: 1;
      grid-row-end: 3; } } }

[dir = 'rtl'] {
  .systemSettings {
    .scroll {
      padding-right: 0;
      padding-left: 16px; }
    .settings-card {
      .card-header {
        .btn {
          right: unset;
          left: 6px;
          margin-left: 0; } } } } }


@media screen and (max-width: $window-large) {
  .settings-grid {
    display: flex;
    flex-direction: column; } }

