

.devicesList-section-content {
  .device:not(:last-child) {
    border-bottom: 1px solid var(--line-table); } }

.devicesList--twoCols {
  .devicesList-section-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }

  .devicesList-section-content .device {
    width: 50%;
    border-radius: 0;
    position: relative;
    border-bottom: none;

    &:nth-child(n + 3) {
      border-top: 1px solid var(--line-table); }

    &:nth-child(even) {
      border-left: 1px solid var(--line-table); }

    &:first-child {
      border-top-left-radius: 2px;

      + .device {
        border-top-right-radius: 2px; } }

    &:last-child {
      border-bottom-right-radius: 2px;

      &:nth-child(odd) {
        border-bottom-left-radius: 2px; } }

    &:nth-last-child(2):nth-child(odd) {
      border-bottom-left-radius: 2px; } } }

.devicesList-section-title {
  line-height: 34px;
  padding: 0 24px;
  font-size: var(--font-size-small);
  color: var(--text-heading2-subtitle);
  font-weight: 500;
  text-transform: uppercase;
  position: sticky;
  z-index: 2; }

[dir = 'rtl'] {
  .devicesList-section-content {
    .device-openMark {
      left: unset;
      right: 12px; }
    .device-walktestMark {
      margin-right: unset;
      margin-left: 16px; } } }
