/***
 *     _______ _______ _______ _______ _______ _______ _______ _______ _______
 *    |   _   |_     _|_     _|    ___|    |  |_     _|_     _|       |    |  |
 *    |       | |   |   |   | |    ___|       | |   |  _|   |_|   -   |       |
 *    |___|___| |___|   |___| |_______|__|____| |___| |_______|_______|__|____|
 *
 *    PLEASE AWARE OF CHANGES IN VERTICAL DIMENSION
 *
 *    There is some optimizations to render huge configurations lists
 *    So please make changes simultaneously with javascript code.
 *
 *    See `/src/components/Configuration/measure.js`
 */



.configuration {
  .table-header {
    margin-top: 16px;
    margin-bottom: 4px;
    flex-shrink: 0;
    padding-left: 24px; }

  .table-header-cell {
    width: 40%;
    padding: 8px 24px 8px 40px;

    &:first-child {
      width: 30%;
      max-width: 250px;
      padding: 0; }

    &:last-child {
      width: 30%;
      padding-left: 48px; } }

  .form-row {
    height: 60px;
    padding: 0 0 0 24px; }

  .form-row-label {
    width: 30%;
    max-width: 250px; }

  .form-row-content {
    width: 40%;
    padding: 0 24px 0 40px;
    position: relative;

    &:last-child {
      width: 60%; } }

  .configuration-backup {
    opacity: .5;
    width: 30% !important;
    padding-left: 48px; }

  .form-row--highlight .configuration-backup {
    opacity: 1; }

  .sidebar {
    width: 30%;
    max-width: 400px;

    .hint {
      margin-top: 12px;
      margin-left: 32px; }

    .scroll {
      padding-top: 16px; } } }

.configuration-link {
  .configuration-links {
    margin-left: 20px; } }

.configuration-link-row {
  position: relative;
  height: 40px;
  padding-left: 32px;
  line-height: 40px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.configuration-link-row--highlight {
  background-color: var(--button-light); }

.configuration-link-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 40px;
  line-height: 40px;

  .btn-content {
    padding: 0;
    transform: rotate(-90deg);
    transition: transform  var(--time) var(--ease-md); }

  .btn:active:before, &.btn:focus:before, &.btn--active:before {
    opacity: 0 !important; } }

.configuration-link-button--active .btn-content {
  transform: none; }

.configuration-backup {
  position: relative; }

.configuration-undo {
  position: absolute;
  top: 0;
  left: 0;

  .btn-icon {
    vertical-align: -3px; } }

.configuration-backup-restore {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  .btn-icon {
    transform: rotate(90deg); } }

.configuration-header {
  flex-shrink: 0;
  padding: 0 24px 16px 0;

  .definition {
    margin-left: 16px; } }

.configuration-content {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 18px;

  &:first-child {
    padding-top: 16px; }

  .table {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0; } } }
.bottom-configuration-scrolled {
  margin-bottom: 16px; }
.configuration-title {
  font-weight: 500;
  margin: 48px 24px 0;
  height: 40px;
  line-height: 40px;

  &:first-child {
    margin-top: 0;
    padding-top: 16px;
    height: 56px; }

  + .configuration-title {
    margin-top: 0; } }

.configuration-stub + .configuration-title {
  margin-top: 0; }

.configuration-title--0 {
  font-size: var(--font-size-large); }

.configuration-title--1 {
  font-size: var(--font-size-medium); }

.configuration-section {
  padding-bottom: 32px;
  &:last-child {
    padding-bottom: 0; }
  .form-row-label {
    max-width: 400px; } }

.configuration-section-title {
  line-height: 34px;
  height: 34px;
  white-space: nowrap;
  padding: 0 24px;
  font-size: var(--font-size-small);
  color: var(--text-heading2-subtitle);
  font-weight: 500;
  text-transform: uppercase; }

.configuration-bitset-viewer {
  text-overflow: ellipsis;
  overflow : hidden;
  white-space: nowrap;
  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all; } }

[dir = 'rtl'] {
  .configuration-link-row {
    padding-left: unset;
    padding-right: 32px;
    .configuration-link-button {
      left: unset;
      right: 0; }
    .form-row {
      padding: 0 24px 0 0;
      .form-row-content {
        padding: 0 40px 0 24px; } } }
  .configuration {
    margin-right: -16px;
    .configuration-section {
      .form-row {
        padding: 0 24px 0 0; } }
    .sidebar {
      .hint {
        margin-right: 32px;
        margin-left: unset; } }
    .form-row--changed {
      .configuration-undo {
        left: unset;
        right: 0; } } } }
