.blindModalWithPagination {
  .card {
    display: flex;
    flex-direction: column; }

  .card-header {
    height: 68px;
    padding: 16px 24px 16px 32px;
    display: flex;
    background-color: var(--background-success);
    border-bottom: 1px solid var(--line-general); }

  .card-close {
    position: static;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;

    >svg {
      width: 16px;
      height: 16px;
      margin: auto; } }

  .card-content {
    padding: 16px 0 0 0;
    flex-grow: 1;
    display: flex;
    flex-flow: column; }

  .tabs {
    padding: 0 32px; }

  .card-actions {
    background-color: var(--background-success);
    border-top: 1px solid var(--line-general);
    padding: 16px 32px; } }

[dir="rtl"] .blindModalWithPagination-paginator-prev {
  .btn-icon {
    transform: rotate(180deg); } }

[dir="rtl"] .blindModalWithPagination-paginator-next {
  .btn-icon {
    transform: rotate(180deg); } }

[dir="rtl"] {
  .blindModalWithPagination {
    .card-close, .card-header-close {
      margin-right: auto;
      margin-left: 8px; } }

  .second-blind-modal-header {
    justify-content: flex-end;
    position: relative;
    .card-header-back {
      position: absolute;
      right: 0;
      transform: rotate(180deg); }
    .definition {
      direction: rtl !important;
      padding-right: 24px; } } }
