.remoteInspection-icon--fail {
  color: var(--color-error); }

.remoteInspection-icon--unknown {
  opacity: 0.5; }

// Panel page calendaric remote inspection
.calendar-header {
  margin-top: 0;
  .remoteInspection-btns {
    margin-left: auto; } }

.remoteInspection-rriResultProgress {
  width: 100%;
  height: 24px;

  .progress-value {
    line-height: 24px; } }

[dir = 'rtl'] {
  .calendar-header {
    margin: 0 4px 16px 12px;
    .remoteInspection-btns {
      margin-left: unset;
      margin-right: auto; } } }
