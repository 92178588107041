.dscConnection-form {
  .col:not(:last-child) {
    margin-right: 8px; }
  .col:not(:first-child) {
    margin-left: 8px; }
  .form-section-header {
    font-size: var(--font-size-primary);
    line-height: 16.41px;
    font-weight: 500;
    margin-top: 0; } }

.dls-dsc-main-form {
  .card-actions {
    padding-top: 32px; }
  .card-content {
    padding-bottom: 17px; }
  .input:not(.input--dirty):not(.input--focus) .form-field-label {
    font-size: var(--font-size-primary);
    line-height: 16.41px;
    font-weight: 400; } }

.dls-protocols-header {
  margin-top: 25px !important; }

.dls-smart-communicator-main-form {
  .card-content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    & > .form-field {
      height: 62px;
      margin: 0;

      &.form-field--togglebox, &.password-field {
        margin-top: 18px; }

      &--togglebox {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        .checkbox-label {
          margin-left: 0;

          .connect-smartcomm-form-toggle-box-label {
            em {
              font-style: normal;
              color: var(--text-heading2-subtitle);
              &:before {
                content: '\00a0'; } } } } } } }

  .card-actions {
    .btn {
      min-width: 89px;
      .btn-content {
        padding: 0; } } } }
