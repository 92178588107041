.installerBlind-info {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;

  .installerBlind-info-section {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    max-width: 250px; }

  .installerBlind-info-section:first-child {
    padding-right: 8px; }

  .installerBlind-info-section + .installerBlind-info-section {
    padding-left: 8px; }

  .installerBlind-info-item {
    white-space: nowrap;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;

    .definition-icon {
      min-width: 16px; } }

  .installerBlind-info-item + .installerBlind-info-item {
    margin-top: 6px; }

  .installerBlind-info-link {
    color: var(--color-primary); } }

.installerBlind-addPanel-header {
  white-space: nowrap;
  width: 500px;

  .definition-detail {
    flex: 1;
    width: 0;
    display: flex;

    .tooltip {
      overflow: hidden; } } }

[dir = 'rtl'] {
  .installerBlind-info-name, .installerBlind-info-section {
    position: relative;
    .definition-title {
      margin-right: 24px; }
    svg {
      position: absolute;
      right: 0; } } }
