.reports-page-table {
  .table-row {
    padding-right: 6px; }
  .table-cell.reports-table-download-cell,.table-cell.reports-table-review-cell {
    &:last-child {
      display: flex;
      justify-content: center;
      .table-cell-content {
        padding: 8px 0; } } }

  .table-cell.reports-table-download-cell {
    .table-cell-content {
      padding-right: 0; } } }

.report-download-drop-down-button {
  background-color: var(--button-light);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: 49px;
  height: 28px;

  .btn-content {
    color: var(--icon-primary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 0;
    gap: 4px;

    .btn-icon {
      margin-right: 0;
      max-width: 18px;
      width: 18px;
      height: 18px;
      transform: scale(0.77); }

    .dropDown-icon {
      margin-left: 0; } } }

.dropDown-container {
  max-width: 224px;
  .slideDown {
    background-color: var(--background-menu-dropdown); } }

.tooltip-container {
  z-index: 61; }

.report {
  width: 224px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  &>div {
    width: 100%;
    &:not(:last-child) {
      padding-bottom: 12px;
      border-bottom: 1px solid var(--line-general); } }

  .report-title-tooltip {

    .definition-title {
      max-width: 201px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; } }

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .definition-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 4px; }

    .definition-detail {
      font-size: 12px;
      line-height: 14px;
      font-style: normal;
      font-weight: 500; } }

  .hint, .title-hint {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 7px;
    color: var(--text-heading2-subtitle); }

  .title-hint {
    display: flex;
    gap: 4px;
    flex-direction: column;
    margin-bottom: 0;
    text-transform: none;
    color: var(--text-heading1);

    span {
      display: flex;
      justify-content: flex-start;
      gap: 7px;
      align-items: center;

      svg {
        color: var(--icon-primary);
        width: 16px;
        height: 16px; } } }

  .report-section-list {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 20px;
    li {
      padding-left: 2px; } } }

.report-download {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 8px;
  .btn.btn--flat {
    display: flex;
    background-color: var(--button-light)!important;
    opacity: 1;
    &:active, &:focus {
      opacity: 1;
      background-color: var(--button-light)!important;
      box-shadow: none!important; } } }

.report-download-items {
  display: flex;
  width: 154px;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
  gap: 4px;
  .report-download-nav-link {
    width: 100%;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    gap: 7px;
    &:hover {
      background-color: var(--button-light); }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px; } } }


[dir = 'rtl'] {
  .create-report-modal-form {
    .form-fields-item:not(:first-child) {
      margin-left: unset;
      margin-right: 12px; }
    .checkbox-label {
      margin-left: 8px;
      margin-right: 8px; }
    .radiobox-label {
      margin-left: 8px; } }

  .reports-table-state-report-cell {
    position: relative;
    padding-right: 24px;
    svg {
      position: absolute;
      right: 0; } }

  .reports-page-table {
    .table-row {
      padding-right: 0;
      padding-left: 6px;

      .table-cell.table-cell.reports-table-download-cell {
        &:last-child {
          .report-download-drop-down-button {
            margin-right: unset!important; } }
        .table-cell-content {
          padding-left: 0; } } } }

  .report-download-drop-down-button {
    .btn-content {
      .btn-icon {
        margin-left: 0; }
      .dropDown-icon {
        margin-right: 0; } } }

  .report-download-items {
    .report-download-nav-link {
      svg {
        margin-left: unset!important; } } }

  .report-section-list {
    margin-right: 20px;
    li {
      padding-right: 2px; } } }
