@import "~ipmp-react-ui/themes/themes";

 :root {
  --role-master-color: rgb(222, 174, 3);
  --role-supervision-color: rgb(3, 222, 209);
  --role-default-color: rgb(215, 102, 255); }

.theme--sanMarino {
  --eventRule-master-steps-icon-title-active-color: var(--text-heading1);

  .login {
    background-image: url('../../assets/Bg.png'); } }

.theme--valencia {
  --eventRule-master-steps-icon-title-active-color: var(--text-additional);

  .app-aside-logo {
    color: white; }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .2); }

  .login {
    background: #00213F linear-gradient(to bottom, #00213F, #0B163F, #10113F); }

  .about .card-header {
    background: #00539E linear-gradient(45deg, #10113F, #00213F); }

  .login-form--header {
    color: white; }

  .device-image {
    opacity: .7; }

  .device--disabled .device-image {
    opacity: .4; }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px var(--card-primary) inset !important;
    -webkit-text-fill-color: white !important; }

  .login {
    background: linear-gradient(rgba(3, 32, 63, 0.7), rgba(3, 32, 63, 0.7)), url('../../assets/Bg.png'); } }
